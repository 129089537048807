const TRANSLATABLE_METADATA_TEMPLATE_ATTRIBUTES_LIST = [
    "name",
    "headline",
    "subHeadline"
];
Object.freeze(TRANSLATABLE_METADATA_TEMPLATE_ATTRIBUTES_LIST);
const COPY_ONLY_METADATA_TEMPLATE_ATTRIBUTES_LIST = [
    "name",
    "headline"
];
Object.freeze(COPY_ONLY_METADATA_TEMPLATE_ATTRIBUTES_LIST);
export { COPY_ONLY_METADATA_TEMPLATE_ATTRIBUTES_LIST, TRANSLATABLE_METADATA_TEMPLATE_ATTRIBUTES_LIST };
