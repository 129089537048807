var EExtendsMergeStrategy;
(function(EExtendsMergeStrategy) {
    EExtendsMergeStrategy["Append"] = "append";
    EExtendsMergeStrategy["Overwrite"] = "overwrite";
    EExtendsMergeStrategy["Clear"] = "clear";
    /**
     * Introducing Keep as strategy to keep values from extended template.
     *
     * @see https://app.clickup.com/t/861m6y395 (CU-861m6y395)
     * */ EExtendsMergeStrategy["Keep"] = "keep";
})(EExtendsMergeStrategy || (EExtendsMergeStrategy = {}));
var ETemplateStatus;
(function(ETemplateStatus) {
    ETemplateStatus["Draft"] = "draft";
    ETemplateStatus["Published"] = "published";
})(ETemplateStatus || (ETemplateStatus = {}));
var ERcbTier;
(function(ERcbTier) {
    ERcbTier["Free"] = "free";
    ERcbTier["Pro"] = "pro";
})(ERcbTier || (ERcbTier = {}));
var EWhenOneOfCondition;
(function(EWhenOneOfCondition) {
    EWhenOneOfCondition["IsWordPressPluginOrThemeActive"] = "is-wordpress-plugin-or-theme-active";
})(EWhenOneOfCondition || (EWhenOneOfCondition = {}));
var ETemplateMetaDataVersionUpdateState;
(function(ETemplateMetaDataVersionUpdateState) {
    ETemplateMetaDataVersionUpdateState["Initial"] = "initial";
    ETemplateMetaDataVersionUpdateState["Update"] = "update";
    ETemplateMetaDataVersionUpdateState["Notify"] = "notify";
    ETemplateMetaDataVersionUpdateState["Removed"] = "removed";
    ETemplateMetaDataVersionUpdateState["NeverReleased"] = "never-released";
})(ETemplateMetaDataVersionUpdateState || (ETemplateMetaDataVersionUpdateState = {}));
var TemplateMetaDataField;
/**
 * Represents the fields of a base template meta data.
 */ (function(TemplateMetaDataField) {
    TemplateMetaDataField["CreatedAt"] = "createdAt";
    TemplateMetaDataField["EnabledWhenOneOf"] = "enabledWhenOneOf";
    TemplateMetaDataField["Extends"] = "extends";
    TemplateMetaDataField["ExtendedMergeStrategies"] = "extendedMergeStrategies";
    TemplateMetaDataField["ExtendedTemplateId"] = "extendedTemplateId";
    TemplateMetaDataField["ExtendsId"] = "extendsId";
    TemplateMetaDataField["ExtendsIdentifier"] = "extendsIdentifier";
    TemplateMetaDataField["Headline"] = "headline";
    TemplateMetaDataField["Id"] = "id";
    TemplateMetaDataField["Identifier"] = "identifier";
    TemplateMetaDataField["IsDeleted"] = "isDeleted";
    TemplateMetaDataField["IsExtendingMetaData"] = "isExtendingMetaData";
    TemplateMetaDataField["IsFavourite"] = "isFavourite";
    TemplateMetaDataField["MachineTranslationStatus"] = "machineTranslationStatus";
    TemplateMetaDataField["IsHidden"] = "isHidden";
    TemplateMetaDataField["LastEditedBy"] = "lastEditedBy";
    TemplateMetaDataField["Logo"] = "logo";
    TemplateMetaDataField["LogoId"] = "logoId";
    TemplateMetaDataField["MinRequiredRcbVersion"] = "minRequiredRcbVersion";
    TemplateMetaDataField["Name"] = "name";
    TemplateMetaDataField["NameTranslationFlags"] = "nameTranslationFlags";
    TemplateMetaDataField["Next"] = "next";
    TemplateMetaDataField["NextId"] = "nextId";
    TemplateMetaDataField["Pre"] = "pre";
    TemplateMetaDataField["PreId"] = "preId";
    TemplateMetaDataField["RecommendedWhenOneOf"] = "recommendedWhenOneOf";
    TemplateMetaDataField["ReleaseStatus"] = "releaseStatus";
    TemplateMetaDataField["SubHeadline"] = "subHeadline";
    TemplateMetaDataField["Status"] = "status";
    TemplateMetaDataField["SuccessorOfIdentifier"] = "successorOfIdentifier";
    TemplateMetaDataField["SuccessorOfIdentifierInfo"] = "successorOfIdentifierInfo";
    TemplateMetaDataField["Tier"] = "tier";
    TemplateMetaDataField["TranslationInfo"] = "translationInfo";
    TemplateMetaDataField["Version"] = "version";
})(TemplateMetaDataField || (TemplateMetaDataField = {}));
export { TemplateMetaDataField, EExtendsMergeStrategy, ERcbTier, ETemplateMetaDataVersionUpdateState, ETemplateStatus, EWhenOneOfCondition };
