import { DEFAULT_LANGUAGE } from "@devowl-wp/api-real-cookie-banner";
import { useQuery } from "@tanstack/react-query";
import { getTranslation } from "../api/real-cookie-banner";
import { useStores } from "../store";
import { QueryKeys } from "../util/query-keys";
/**
 * Use translation hook to translate text to a target language.
 * @param targetLanguage - The target language to translate to.
 * @param texts - The text to translate. An array of strings.
 * @returns the useQuery object
 */ function useTranslate(targetLanguage, texts) {
    const { rcbAuthenticationStore } = useStores();
    const releaseQuery = useQuery(QueryKeys.getTranslationByTargetAndText(targetLanguage, texts), (param)=>{
        let { signal } = param;
        var _rcbAuthenticationStore_session;
        return getTranslation({
            signal,
            headers: {
                Authorization: `Bearer ${rcbAuthenticationStore === null || rcbAuthenticationStore === void 0 ? void 0 : (_rcbAuthenticationStore_session = rcbAuthenticationStore.session) === null || _rcbAuthenticationStore_session === void 0 ? void 0 : _rcbAuthenticationStore_session.jwt}`
            },
            body: {
                options: {
                    target: targetLanguage,
                    texts: texts
                }
            }
        });
    }, {
        staleTime: Infinity,
        retry: false,
        enabled: !!targetLanguage && !!texts
    });
    return releaseQuery;
}
/**
 * Fetch translation from the API.
 * @param targetLanguage - The target language to translate to.
 * @param texts - The text to translate. String or an array of strings.
 * @param rcbAuthenticationStore - The authentication store.
 * @returns the translation response or undefined
 */ async function fetchTranslations(targetLanguage, texts, rcbAuthenticationStore) {
    if (targetLanguage && texts && rcbAuthenticationStore) {
        var _rcbAuthenticationStore_session;
        if (!Array.isArray(texts)) {
            texts = [
                texts
            ];
        }
        const response = await getTranslation({
            signal: new AbortController().signal,
            headers: {
                Authorization: `Bearer ${rcbAuthenticationStore === null || rcbAuthenticationStore === void 0 ? void 0 : (_rcbAuthenticationStore_session = rcbAuthenticationStore.session) === null || _rcbAuthenticationStore_session === void 0 ? void 0 : _rcbAuthenticationStore_session.jwt}`
            },
            body: {
                options: {
                    source: DEFAULT_LANGUAGE,
                    target: targetLanguage,
                    texts: texts
                }
            }
        });
        return response;
    }
    return undefined;
}
export { useTranslate, fetchTranslations };
